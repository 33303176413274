<template>
  <div class="contents">
    <p>点击图片点燃烟花,左右滑动选择类型</p>
    <swiper
      class="swiper"
      ref="mySwiper"
      :options="swiperOption"
    >
      <swiper-slide v-for="index in [1,2,3,4,5]" :key="index">
        <img :alt="index" :src="require(`@/assets/fireworks/${index}.jpg`)" @click="setOffFirework(index)" />
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

export default {
  name: "Fireworks",
  data() {
    return {
      socketIO: {},
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    setOffFirework (index) {
      this.socketIO.io.emit("client_set_off", 'luxunroad', {kind: index})
    }
  },
  mounted(){
    this.socketIO = new VueSocketIO({
      connection: SocketIO(process.env.VUE_APP_SOCKET_SERVER_FIREWORK_URL, {
        transports: ["websocket", "polling"]
      })
    })
  }
}
</script>

<style scoped>
.contents {
  width: 100%;
  min-height: 1080px;
  padding-top: 20%;
  background: url(../assets/fireworks/bg.png) center no-repeat;
}

.swiper-wrapper img{
  width: 100%;
  height: auto;
}
.contents p {
  text-align: center;
  font-size: large;
  top: -2rem;
  font-weight: bold;
  padding-bottom: 20px;
}
</style>

<style>
  div.head-nav{
    display: none;
  }
</style>